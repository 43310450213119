.Editor {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.EditorContainer {
  border: 2px solid #f2f5f9;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  background-color: #fff;
}

.EditorContainer:focus-within {
  border: 2px solid #753db6;
}

.Toolbar {
  border: none;
  border-bottom: 2px solid #f2f5f9;
  background-color: #f2f5f9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.EditorTextArea {
  padding: 0 1em;
  box-sizing: border-box;
  font-size: 0.85em;
}

.EditorTextArea h1 {
  font-size: 26px;
  margin: 0;
}

.EditorTextArea h2 {
  font-size: 21px;
  margin: 0;
}

.EditorTextArea h3 {
  font-size: 14px;
  margin: 0;
}

.EditorTextArea h4 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.Label {
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: rgb(77, 77, 77);
}
