.Overlay {
  background-color: rgba(0,0,0,0.7);
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  box-sizing: border-box;
}

.Modal {
  width: 50vw;
  background-color: #fff;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 16px;
  box-sizing: border-box;
  z-index: 1;
}

/* Hack to make the bottom padding/margin work inside the absolute positioned modal overlay */
.Modal::before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  pointer-events: none;
}

.Header {
  display: flex;
  padding: 1.6rem 1.5rem 2rem 1.5rem;
}

.TitleDescriptionContainer {
  flex: 1;
}

.Icon {
  font-size: 1.4rem;
  padding-right: 0.8rem;
}

.Title {
  font-weight: bold;
  font-size: 1.4rem;
}

.Description {
  color: #878c96;
  font-size: 0.9rem;
  margin-top: 0.4rem;
}

.CloseIcon {
  font-size: 1.4rem;
  padding-left: 0.8rem;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.5s;
  margin-top: -0.4rem;
}

.CloseIcon:hover {
  opacity: 1;
}

.ModalFixedHeaderContent {
  padding: 0 1.5rem;
}

.Content {
  display: flex;
  flex-direction: column;
  /* max-height: 50vh; */
  overflow-y: scroll;
  padding: 1rem 1.5rem;
}

.ModalActionList {
  display: flex;
  gap: 10px;
  background-color: #f9fbfc;
  padding: 1.6rem 1.5rem 1.6rem 1.5rem;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 2px solid #f2f5f9;
}

.ModalActionList button {
  flex: 1;
}

.BottomSpacer {
  height: 5rem;
}
