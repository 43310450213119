.Menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100vh;
  padding: 1.3em;
  box-sizing: border-box;
  position: fixed;
  WIDTH: 220px;
}

.Menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #878c96;
}

.MenuOption {
  font-weight: bold;
  font-size: 0.9em;
  cursor: pointer;
  display: flex;
}

.MenuOptionSelected {
  composes: MenuOption;
}

.MenuOption a {
  text-decoration: none;
  color: #878c96;
  flex: 1;
  padding: 0.6em 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.MenuOptionSelected a {
  color: #000;
}

.Icon {
  font-size: 1.3em
}

.Label {
  margin-left: 0.8em;
}

.UserTile {
  background-color: #fff;
  border: 1px solid #e5e4e8;
  padding: 0.3em;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.UserLogo {
  flex: 2;
  width: 1.7rem;
  height: 1.7rem;
}

.UserDetails {
  flex: 5;
  padding: 3px;
  box-sizing: border-box;
  min-width: 0
}

.UserName {
  font-size: 0.8rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserEmail {
  font-size: 0.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.UserMenuHandle {
  flex: 1;
  display: flex;
  justify-content: end;
  padding-right: 0.5rem;
}
