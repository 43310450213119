.Main {
  display: flex;
  flex: 1;
  min-height: 100vh
}

.Nav {
  background-color: #f2f5f9;
  min-width: 220px;
  min-height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #e5e4e8;
  display: flex;
}

.Header {
  border-bottom: 1px solid #e5e4e8;
  width: 100%;
  padding: 0.7em 1em;
  box-sizing: border-box;
}

.Content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Body {
  padding: 0.7em 1em;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
}
