.TabSelectorListContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.TabSelectorList {
  background-color: #f2f5f9;
  display: flex;
  padding: 4px;
  border-radius: 6px;
  margin-bottom: 0.9em;
  font-size: 0.85em;
  gap: 10px;
}

.TabSelector {
  color: #878c96;
  font-weight: bold;
  padding: 0.4em 0.6em;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;
}

.TabSelectorSelected {
  composes: TabSelector;
  background-color: #fff;
  color: #753db6;
}

.TabSelectorActionList {
  display: flex;
  gap: 10px;
}

.TabSelectorActions {

}
