.DropDownContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.Label {
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: rgb(77, 77, 77);
}

.DropDown {
  border: 2px solid #f2f5f9;
  border-radius: 8px;
  height: 35px;
  box-sizing: border-box;
  outline: none;
  padding-left: 4px;
}

.DropDown:focus {
  border: 2px solid #753db6;
}
