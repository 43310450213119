.ThrobberContainer {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes heart-rotate {
   0% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(0deg);}
  15% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(0deg);}
  25% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(90deg);}
  40% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(90deg);}
  50% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(180deg);}
  65% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(180deg);}
  75% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(270deg);}
  90% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(270deg);}
 100% {transform: translate(-50%,-50%) rotateX(90deg) rotateZ(360deg);}
}

@keyframes color-change1 {
   0% {background-color: #cf1146;}
  15% {background-color: #cf1146;}
  25% {background-color: #cf1146;}
  30% {background-color: #f091ba;}
  40% {background-color: #f091ba;}
  50% {background-color: #f091ba;}
  65% {background-color: #f091ba;}
  75% {background-color: #f091ba;}
  90% {background-color: #cf1146;}
 100% {background-color: #cf1146;}
}

@keyframes color-change2 {
   0% {background-color: #e2878d;}
  15% {background-color: #e2878d;}
  25% {background-color: #e2878d;}
  30% {background-color: #e2878d;}
  40% {background-color: #e2878d;}
  50% {background-color: #e23d5c;}
  65% {background-color: #e23d5c;}
  75% {background-color: #e23d5c;}
  90% {background-color: #e23d5c;}
 100% {background-color: #cf1146;}
}

.Throbber {
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  background-color: black;
  transform: translate(-50%,-50%) rotateX(90deg);
  animation: heart-rotate 3s infinite linear;
}

.Half1 {
	position: absolute;
	left: 50%;
  top: 50%;
	height: 70px;
	width: 32px;
	overflow: hidden;
	transform-style: preserve-3d;
  transform-origin: 0 50%;
  transform: translate(0%,-50%) rotateX(-90deg);
}

.Half1:after {
	content: "";
	position: absolute;
	left: -15px;
	border-top-left-radius: 30px;
	border-top-right-radius: 50px;
  width: 40px;
	height: 60px;
	transform: rotate(45deg);
  animation: color-change1 3s infinite linear;
}

.Half2 {
	position: absolute;
	left: 50%;
  top: 50%;
	height: 70px;
	width: 32px;
	overflow: hidden;
	transform-style: preserve-3d;
  transform-origin: 0 50%;
  transform: translate(0%,-50%) rotateX(-90deg) rotateY(90deg);
}

.Half2:after {
	content: "";
	position: absolute;
	left: -15px;
	border-top-left-radius: 30px;
	border-top-right-radius: 50px;
  width: 40px;
	height: 60px;
	transform: rotate(45deg);
  animation: color-change2 3s infinite linear;
}

.Half3 {
	position: absolute;
	left: 50%;
  top: 50%;
	height: 70px;
	width: 32px;
	overflow: hidden;
	transform-style: preserve-3d;
  transform-origin: 0 50%;
  transform: translate(0%,-50%) rotateX(-90deg) rotateY(180deg);
}

.Half3:after {
	content: "";
	position: absolute;
	left: -15px;
	border-top-left-radius: 30px;
	border-top-right-radius: 50px;
  width: 40px;
	height: 60px;
	transform: rotate(45deg);
  animation: color-change1 3s infinite linear;
}

.Half4 {
	position: absolute;
	left: 50%;
  top: 50%;
	height: 70px;
	width: 32px;
	overflow: hidden;
	transform-style: preserve-3d;
  transform-origin: 0 50%;
  transform: translate(0%,-50%) rotateX(-90deg) rotateY(270deg);
}

.Half4:after {
	content: "";
	position: absolute;
	left: -15px;
	border-top-left-radius: 30px;
	border-top-right-radius: 50px;
  width: 40px;
	height: 60px;
	transform: rotate(45deg);
  animation: color-change2 3s infinite linear;
}
