.Table {
  width: 100%;
  font-size: 0.85em;
  border-collapse: collapse;
}

.Table thead {
  background-color: #f9fbfc;
  color: #878c96;
  font-weight: bold;
}

.Table thead tr,
.Table tbody tr {
  border-bottom: 2px solid #f2f5f9;
}

.Table tbody tr:hover {
  background-color: #f9fbfc;
}

.Table thead td {
  padding: 0.7em;
  box-sizing: border-box;
}

.Table tbody {
  font-weight: bold;
}

.Table tbody td {
  padding: 1em 0.7em;
  box-sizing: border-box;
  white-space: nowrap;
}

.Table button {
  color: #000;
  padding: 0;
  display: block;
  height: initial;
}

.Table button:not(:last-child) {
  margin-bottom: 0.5rem;
}
