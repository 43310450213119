.Checkbox {
	-moz-appearance:none;
	-webkit-appearance:none;
	-o-appearance:none;
	outline: none;
	content: none;
  cursor:
}

.Checkbox:before {
	font-family: "Font Awesome 6 Free";
  content: "\f00c";
  font-size: 15px;
  color: transparent !important;
  display: block;
  width: 15px;
  height: 15px;
  border: 2px solid #dfdfdf;
  border-radius: 4px;
}

.Checkbox:checked:before {
	color: #b5b5b5 !important;
}
