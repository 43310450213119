.Alert {
  border: 1px solid black;
  padding: 0.7rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 8px;
  margin: 1em 0;
}

.AlertSuccess {
  composes: Alert;
  background-color: #e8f6ec;
  border: 1px solid #dcf1e3;
}

.AlertInfo {
  composes: Alert;
  background-color: #e2edf9;
  border: 1px solid #cadef6;
}

.AlertWarning {
  composes: Alert;
  background-color: #fef7e6;
  border: 1px solid #fdefd3;
}

.AlertError {
  composes: Alert;
  background-color: #fceae6;
  border: 1px solid #fceae6;
}

.Icon {
  margin-right: 0.7rem;
  font-size: 1.3em;
  width: 1em;
  height: 1em;
  color: #fff;
  background-color: #000;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.IconSuccess {
  composes: Icon;
  background-color: #36b555;
}

.IconInfo {
  composes: Icon;
  background-color: #055dd4;
}

.IconWarning {
  composes: Icon;
  background-color: #ed8a01;
}

.IconError {
  background-color: #e84628;
}
