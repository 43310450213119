.Base {
  border: none;
  font-size: 0.85rem;
  font-weight: bold;
  padding: 0.7rem 0.85rem;
  border-radius: 8px;
  cursor: pointer;
  height: 35px;
  box-sizing: border-box;
  white-space: nowrap;
  transition: opacity 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration 0.5s;
}

.Icon {
  display: inline-block;
  margin-right: 0.5rem;
}

.Button[disabled] {
  opacity: 0.3;
  background-color: #753db6;
  cursor: not-allowed;
}

.Button {
  background-color: #753db6;
  color: #fff;
}

.Button:hover {
  text-decoration-color: #fff;
}

.Link {
  color: #753db6;
  background: none;
}

.Link:hover {
  text-decoration-color: #753db6;
}

.Button.DangerStyle {
  background-color: transparent;
  border: 1px solid #e94528;
  color: #e94528;
}

.Button.DangerStyle:hover {
  text-decoration-color: #e94528;
}

.Link.DangerStyle {
  color: #e94528;
}

.Link.DangerStyle:hover {
  text-decoration-color: #e94528;
}
