.ContentBlock {
  border: 1px solid #e5e4e8;
  border-radius: 8px;
  background-color: #f9fbfc;
}

.ContentBlock:not(:last-child) {
  margin-bottom: 1em;
}

.ToolBar {
  min-height: 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #f2f5f9;
}

.VisibleToggle {
  padding: 1em;
  box-sizing: border-box;
}

.ContentTitle {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 0
}

.Title {
  display: inline-block;
}

.TitleSummary {
  font-size: 0.8em;
  color: #878c96;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 400px;
  margin-left: 4px;
}

.Actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Action {
  padding: 1em;
  box-sizing: border-box;
}

.Content {
  padding: 1.4em;
  box-sizing: border-box;
}

.ImageContainer {
  align-self: flex-start;
  border: 1px solid #e5e4e8;
  border-radius: 8px;
}
