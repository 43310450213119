.TotalsMenuFilter {
    color: #999;
    font-size: 0.8em;
}

.TotalsMenuFilter::before {
    content: '-';
    padding: 0 5px 0 5px;
}

.Total {
    -webkit-box-shadow: 0px 0px 5px 1px #ccc;
    box-shadow: 0px 0px 5px 1px #ccc;
    border-radius: 3px;
    margin-bottom: 12px;
    padding: 2px;
}

.SearchTextInputContainer {
  margin: 0
}
