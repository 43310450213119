.FormResponse ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

.FormResponse ol li {
    min-height: 24px;
}

.Answer {
    display: flex;
    align-items: center;
}
