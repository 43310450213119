.H1 {
  margin-top: 1.6em;
  font-size: 1.2em;
  font-weight: bold;
}

.H2 {
  margin-top: 1.4em;
  font-size: 1em;
  font-weight: bold;
}
