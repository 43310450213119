.TruncatedText {
  white-space: pre-wrap;
  font-weight: normal;
  font-size: 0.9em;
}

.MoreButton {
  padding-left: 4px;
  font-weight: bold;
  cursor: pointer;
}

.MoreButton:hover {
  text-decoration: underline;
}
