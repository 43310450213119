.TextInputContainer {
  display: flex;
  flex-direction: column;
  margin: 0.6rem 0;
}

.Label {
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: rgb(77, 77, 77);
}

.TextInput {
  border: 2px solid #f2f5f9;
  border-radius: 8px;
  height: 35px;
  box-sizing: border-box;
  outline: none;
  padding-left: 4px;
}

.TextInput:focus {
  border: 2px solid #753db6;
}

.TextInputWithIcon {
  composes: TextInput;
  padding-left: 30px;
}

.Icon {
  position: absolute;
  height: 35px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #878c96;
}
