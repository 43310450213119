.wrapper-class {
  border: 1px solid #cccccc;
  background-color: #fff;
}

.ContentBlock {
  -webkit-box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.1);
  margin-top: 1rem;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 10px 0 10px;
}
