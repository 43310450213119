.BadgeContainer {
  display: flex;
  flex-direction: column;
  height: 23px;
}

.Badge {
  align-self: flex-start;
  font-size: 0.8em;
  padding: 3px 4px;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
}

.Invited {
  composes: Badge;
  background-color: #fbab40;
  /* background-color: #f7f2c1;
  color: #edbe6d;
  border: 1px solid #edbe6d; */
}

.Attending {
  composes: Badge;
  background-color: #03c977;
  /* background-color: #d1f0df; */
  /* color: #65b98d; */
  /* border: 1px solid #65b98d; */
}

.NotAttending {
  composes: Badge;
  background-color: #e04660;
  /* background-color: #edcbca;
  color: #d18682;
  border: 1px solid #d18682; */
}
