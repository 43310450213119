.Unauthenticated {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.Content {
  width: 45vw;
  padding: 2rem;
  box-sizing: border-box;
}

.Image {
  width: 55vw;
}

.Image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
