.StatsList {
  flex: 1;
  display: flex;
  border: 2px solid #f2f5f9;
  border-radius: 10px;
  padding: 1em 0;
  background-color: #f9fbfc;
  max-height: 121px;
  box-sizing: border-box;
}

.Stat {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1em;
}

.Stat:not(:last-child) {
  border-right: 2px solid #f2f5f9;
}

.Title {
  font-weight: bold;
  font-size: 0.9em;
  margin-bottom: 0.7em;
}

.Value {
  font-weight: bold;
  font-size: 2em;
}

.Description {
  font-size: 0.8em;
  margin-top: 0.35em;
  color: #878c96;
}
