.FormResponses {
  list-style: none;
  margin: 0;
  padding: 0;
}

.FormResponse {
  margin: 20px 0 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.FormResponse h3 {
  font-weight: bold;
}

.Question {
  font-size: 1em;
  font-weight: bold;
}

.NoResponse {
  color: #bbb;
  font-style: italic;
  font-size: 0.9em;
}

.RSVPGuestName {
  font-size: 0.9em;
}

.NoResponseYet {
  composes: NoResponse;
  margin: 20px 0;
  display: block;
}

.GuestHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.GuestHeader h1, .GuestHeader button {
  margin: 0;
}

.WebsiteURLContainer {
  display: flex;
  flex-direction: column;
  margin: 0.6rem 0;
}

.WebsiteURLContainer label {
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: rgb(77, 77, 77);
}

.WebsiteURL {
  height: 35px;
  box-sizing: border-box;
  outline: none;
  padding-left: 4px;
  display: flex;
  align-items: center;
}

.WebsiteURL a {
  margin-right: 0.5em;
}

.WebsiteURLIcon {
  font-size: 0.8em;
  color: #878c96;
}
