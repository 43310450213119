.Message {
  -webkit-box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.1);
  margin-top: 1rem;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  padding: 5px;
}

.Avatar {
    font-size: 20px;
    font-weight: bold;
    width: 50px;
    min-width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 10px;
}

.Recipient {
    font-weight: bold;
}

.Timestamp {
    font-size: 12px;
    color: #666;
}

.MessageDetails {
    padding: 0 10px 0 10px;
    box-sizing: border-box;
}

.ErrorDetails,
.SentDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 10px 0;
}

.ErrorIcon,
.SentIcon {
    margin-right: 10px;
}

.ErrorMessage {
    font-family: monospace;
}

.ErrorHasBeenAcknowledged {
    font-style: initial;
    font-weight: bold;
    background-color: rgb(255, 204, 110);
    padding: 0 5px 0 5px;
    display: inline-block;
}

.EmailSubject {
    margin: 10px 0 10px 0;
}

.EmailBody {
    margin-bottom: 10px;
}

.SMSBody {
    margin: 10px 0 10px 0;
    white-space: pre-wrap;
}
